<template>
  <div class="new-order-list">
    <back-header @back="back" title="我的订单"></back-header>
    <div class="content">
      <div class="content-inner">
        <div v-if="orderList" class="orderList-wrap">
          <ul v-if="orderList.length" class="orderList-list">
            <van-pull-refresh
              class="pull-refresh"
              v-model="isRefreshing"
              success-text="刷新成功"
              @refresh="onRefresh"
            >
              <van-list
                v-model="pulling"
                :finished="orderList.length >= total"
                finished-text="没有更多了"
                @load="onLoad"
              >
                <order-item v-for="item in orderList" :key="item.id" :data="item" />
              </van-list>
            </van-pull-refresh>
          </ul>
          <div v-else class="none-data">
            <img
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/no_data.png"
              alt=""
            />
            <span>暂无数据</span>
          </div>
        </div>
        <div v-else class="none-data">
          <img
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/no_data.png"
            alt=""
          />
          <span>暂无数据</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDeliveryInfoPageList } from '@/api/apiv2';
import BackHeader from '@/components/BackHeader';
import OrderItem from './components/OrderItem';

export default {
  components: { BackHeader, OrderItem },
  data() {
    return {
      show: false,
      isRefreshing: false,
      orderList: null, // list, 为啥用null呢，因为需要判断是否加载过数据
      loading: false,
      pulling: false,
      finished: false,
      pageSize: 10,
      pageNo: 1,
      total: 0,
    };
  },
  methods: {
    onLoad() {
      this.getOrderList(true);
    },
    onRefresh() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.getOrderList();
    },
    getOrderList(append = false) {
      if (append) {
        this.pageNo += 1;
      }
      getDeliveryInfoPageList({ pageSize: this.pageSize, pageNo: this.pageNo })
        .then((res) => {
          if (append) {
            this.orderList = [...(this.orderList ?? []), ...res.list];
          } else {
            this.orderList = res.list;
          }
          console.log(this.orderList.length);
          this.total = res.total;
          // console.log(this.orderList);
        })
        .finally(() => {
          this.$loadingCircle.end();
          this.isRefreshing = false;
          this.pulling = false;
        });
    },
    back() {
      window.location.href = 'neolix://more';
    },
  },

  created() {
    this.$loadingCircle.start();

    this.getOrderList();
  },
};
</script>

<style lang="scss" scoped>
.new-order-list {
  font-size: initial;
  background: #f6f7f8;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: auto;
    max-height: calc(100vh - 40px);

    .pull-refresh {
      min-height: 100%;
    }

    .content-inner {
      overflow: auto;
      .none-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        img {
          width: 130px;
          height: 130px;
          margin-top: 30%;
          margin-bottom: 15px;
        }
      }
      .orderList-list {
        margin: 0 14px;
        box-sizing: border-box;
        padding: 0;
        height: calc(100vh - 60px);
      }
    }
  }
}
</style>
